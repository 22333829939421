
import { api } from "@/api/api";
import { ApiGetEmployeeWorkingHourDto, ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseCol from "@/components/shared/form/BaseCol.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRouteComputed, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseWorkingHoursDetailsPage",
  components: { BaseLayout, BaseCol, BaseSheetField, BaseConfirmModalForm, BaseModal },

  setup() {
    const route = useRouteComputed();
    const router = useRouter();
    const store = useStore<StoreState>();
    const courseName = computed(() => store.state.courses.course.courseName);
    const workingHourEntry = ref<ApiGetWorkingHourDto | ApiGetEmployeeWorkingHourDto>();
    const isExternalResource = route.value.params.isExternalResource;
    const unapproveWorkingHourModal = ref(getInitialModalData());

    const showUnapproveModal = useOpenModal(ModalType.Unspecified, "Trekk tilbake", unapproveWorkingHourModal);

    const loadHourRegistered = async () => {
      workingHourEntry.value = (
        await api.economy[isExternalResource === "true" ? "getWorkingHourAsync" : "getEmployeeWorkingHourAsync"](
          +route.value.params.hourEntryId
        )
      ).data;
    };

    const approveWorkingHourEntry = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.value) {
          return;
        }
        await api.economy.approveWorkingHourAsync([workingHourEntry.value.id]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer godkjent");
        await loadHourRegistered();
      });
    };

    const declineWorkingHourEntry = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.value) {
          return;
        }
        await api.economy.declineWorkingHourAsync([workingHourEntry.value.id]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer avvist");
        await loadHourRegistered();
      });
    };

    const unapproveWorkingHourEntry = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        if (!workingHourEntry.value) {
          return;
        }
        await api.economy.unapproveWorkingHourAsync([workingHourEntry.value.id]);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer trukket tilbake");
        unapproveWorkingHourModal.value.showModal = false;
        await loadHourRegistered();
      });
    };

    const navigateBackToWorkingHoursList = () =>
      router.push({
        name: SingleCourseRouteNames.CourseWorkingHours,
        params: {
          id: route.value.params.id,
        },
      });

    onParamChange(loadHourRegistered);

    return {
      courseName,
      workingHourEntry,
      isApproved: computed(() => workingHourEntry.value?.status === WorkingHourStatusEnum.PaymentPending),
      isActuallyApproved: computed(() => workingHourEntry.value?.status === WorkingHourStatusEnum.Approved),
      isPendingApproval: computed(() => workingHourEntry.value?.status === WorkingHourStatusEnum.NotApproved),
      approveWorkingHourEntry,
      declineWorkingHourEntry,
      formatLocalizedDate,
      navigateBackToWorkingHoursList,
      unapproveWorkingHourEntry,
      unapproveWorkingHourModal,
      showUnapproveModal,
    };
  },
});
