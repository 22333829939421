var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.unapproveWorkingHourModal.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 400
    }
  }, [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.unapproveWorkingHourModal
    },
    on: {
      "close": function close($event) {
        _vm.unapproveWorkingHourModal.showModal = false;
      },
      "submit": function submit() {
        return _vm.unapproveWorkingHourEntry();
      }
    }
  }, [_vm._v(" Er du helt sikker på at du vil endre status på de godkjente arbeidstimene ")])], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateBackToWorkingHoursList
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.isPendingApproval ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "outlined": "",
            "color": "white"
          },
          on: {
            "click": _vm.approveWorkingHourEntry
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-sign")]), _vm._v(" Godkjenn ")], 1) : _vm._e(), _vm.isPendingApproval ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "color": "white"
          },
          on: {
            "click": _vm.declineWorkingHourEntry
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-cancel-outline")]), _vm._v(" Avvis ")], 1) : _vm._e(), _vm.isActuallyApproved ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "color": "white"
          },
          on: {
            "click": _vm.showUnapproveModal
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-cancel-outline")]), _vm._v(" Trekk tilbake godkjenning ")], 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_vm.workingHourEntry ? _c('v-row', [_c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.userFullName,
      "label": "Navn på ressurs"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.courseName,
      "label": "Kursnavn"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.courseContractId,
      "label": "Kontrakt-ID"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.courseContractLineDescription,
      "label": "Beskrivelse kontraktslinje"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.costTypeName,
      "label": "Type timer"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.amount,
      "label": "Antall timer"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.formatLocalizedDate(_vm.workingHourEntry.date),
      "label": "Dato"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.statusText,
      "label": "Status"
    }
  })], 1), _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.comment,
      "label": "Kommentar"
    }
  })], 1), _c('BaseCol', [_c('div', {
    staticClass: "mx-2 my-0 text-lg grey--text text--darken-1"
  }, [_vm._v(" Status: "), _c('v-chip', {
    staticClass: "mx-4",
    attrs: {
      "outlined": "",
      "color": !_vm.isApproved ? 'error' : 'success',
      "label": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.workingHourEntry.statusText) + " "), !_vm.isApproved ? _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" mdi-alert-circle-outline ")]) : _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" mdi-check ")])], 1)], 1)]), _vm.isApproved ? _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.workingHourEntry.approvedByUserFullName,
      "label": "Godkjent av"
    }
  })], 1) : _vm._e(), _vm.isApproved ? _c('BaseCol', [_c('BaseSheetField', {
    attrs: {
      "value": _vm.formatLocalizedDate(_vm.workingHourEntry.approvedByDate),
      "label": "Godkjent dato"
    }
  })], 1) : _vm._e()], 1) : _vm._e()]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }