var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', _vm._b({
    class: _vm.customClass,
    attrs: {
      "cols": _vm.cols,
      "xs": _vm.xs,
      "sm": _vm.sm,
      "md": _vm.md,
      "lg": _vm.lg,
      "xl": _vm.xl
    }
  }, 'v-col', Object.assign({}, _vm.$attrs), false), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }